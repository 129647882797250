import imgJorg from "../assets/image/akkadian/Jorg.jpg";
import imgAngela from "../assets/image/akkadian/Angela.jpg";
import imgTitus from "../assets/image/akkadian/Titus.jpg";
import imgKoko from "../assets/image/akkadian/Koko.jpg";
import imgKylie from "../assets/image/akkadian/Kylie.jpg";
import imgChristine from "../assets/image/akkadian/Christine2.jpg";
import imgBryan from "../assets/image/akkadian/Bryan.jpg";
import imgMichael from "../assets/image/akkadian/Michael.jpg";
import imgRoxi from "../assets/image/akkadian/Roxi.jpg"
import imgRicky from "../assets/image/akkadian/Ricky.jpg"
import imgTaren from "../assets/image/akkadian/Taren.jpg"
import imgAviva from "../assets/image/akkadian/Aviva.jpg"

import imgFemale from "../assets/image/akkadian/female.jpg"
import imgMale from "../assets/image/akkadian/male.jpg"
//import imgFiona from "../assets/image/akkadian/Fiona.jpg";


export const clinicians = [
    {
        id: "Jorg",
        title: "Consultant Psychiatrist",
        name: "Associate Professor Jörg Strobel",
        credentials: "MD, FRANZCP, FGLF",
        state: "SA",
        imageFile: imgJorg,
        bio: "Jörg has served patients in regional and rural South Australia for more than 10 years using telehealth. His extensive telepsychiatry experience, coupled with his 30 years of clinical experience, leads Akkadian Health’s mental health services.",
        shortBio: "Jörg has led Akkadian Health's mental health services with 30 years of clinical experience and over 10 years of telepsychiatry experience."
    },
    {
        id: "Angela",
        title: "Consultant Psychiatrist",
        name: "Dr Angela Okungu",
        credentials: "MB BS, FRANZCP",
        state: "NSW",
        imageFile: imgAngela,
        bio: "Angela has extensive experience working in the public health system within South Australia. As a general psychiatrist she has a particular interest in mood disorders (depression), anxiety, and trauma-related disorders. Dr Okungu strongly believes a collaborative approach amongst health professionals is crucial in helping patients along their journey to recovery.",
        shortBio: "Angela has extensive experience working in the public health system, with a focus on mood disorders, anxiety and trauma-related disorders."
    },
    {
        id: "Titus",
        title: "Consultant Psychiatrist",
        name: "Dr Titus Mohan",
        credentials: "MB BS, FRANZCP",
        state: "SA",
        imageFile: imgTitus,
        bio: "Titus is a senior psychiatrist with 20 years of experience. His interests include management of acute psychiatric conditions, assessing biopsychosocial aspects of chronic mental health issues and collaborating closely with GPs. Dr Mohan offers diagnostic assessments and pragmatic management plans along with short follow-ups to ensure psychiatric management is consolidated to each unique set of circumstances.",
        shortBio: "Titus is a senior psychiatrist with 20 years of experience, interested in acute psychiatric conditions, biopsychosocial assessments, and collaborating with GPs."
    },
    {
        id: "Koko",
        title: "Consultant Psychiatrist",
        name: "Dr Edward Ko Ko Aung",
        credentials: "BSc (Hons) Physiotherapy",
        state: "SA",
        imageFile: imgKoko,
        bio: "Edward (KoKo) graduated from the University of Rangoon, Myanmar, in 1991 - moving to Australia in 1996 to complete his psychiatrist training at the University of Adelaide. He has worked at Glenside, the Queen Elizabeth Hospital and in private rooms at Health on Kensington. He is an active member of the American Neuropsychiatric Association with special interests in Neuropsychiatry, adult ADHD and related disorders.",
        shortBio: "KoKo has trained at the University of Adelaide, with a focus on Neuropsychiatry, adult ADHD and related disorders."
    },
    {
        id: "Bryan",
        title: "Consultant Psychiatrist",
        name: "Dr Tuan Anh (Bryan) Bui",
        credentials: "MBBS, FRANZCP",
        state: "QLD",
        imageFile: imgBryan,
        bio: "Bryan completed his medical training in 2014 with Monash University (Vic) where he received the prestigious International Scholarship for Excellence. He practised psychiatry in public hospital positions throughout Queensland before transitioning to private practice in early 2021. Bryan is passionate about evidence-based clinical practice, and has a special interest in adult ADHD and anxiety disorders. He has published in internationally recognised peer-reviewed psychiatry journals and presented at medical conferences. Bryan is also an Associate Lecturer with Griffith University under the Faculty of Medicine and an associate trainee editor of the Australasian Psychiatry scientific journal. He is fluent in both English and Vietnamese.",
        shortBio: "Bryan completed his medical training in 2014, with a focus on evidence-based clinical practice, adult ADHD and anxiety disorders."
    },
    {
        id: "Michael",
        title: "Consultant Psychiatrist",
        name: "Dr Michael Zou",
        credentials: "MBBS(hons), M.Psych, FRANZCP",
        state: "VIC",
        imageFile: imgMichael,
        bio: "Michael graduated from Monash University Medical School in 2014 and completed his psychiatry training in Victoria. He has undertaken work in a number of subspecialty rotations including Psychiatry of Old Age, Forensic Psychiatry and Psychotherapies. He continues to work as a consultant psychiatrist in a large public psychiatry hospital in Victoria focusing on general adult psychiatry. He is passionate about the destigmatisation of mental illness and delivering high quality care to his patients.",
        shortBio: "Michael completed his medical training in 2014, with a focus on general adult psychiatry and destigmatising mental illness."
    },    
    {
        id: "Ricky",
        title: "Consultant Psychiatrist",
        name: "Dr Ricky Hang",
        credentials: "MBBS, BMSc, M.Psych, FRANZCP",
        state: "VIC",
        imageFile: imgRicky,
        bio: "Ricky completed his undergraduate medical training at the University of Melbourne in 2013, and completed his psychiatry training with a major metropolitan health service in Victoria. His area of interest lies in adult mental health, with experience in a range of subspecialties including Access/Triage, Consultation Liaison, Forensic Psychiatry and psychotherapies. He continues to work in adult psychiatry in a large public hospital. Ricky is experienced in the assessment and management of schizophrenia, mood disorders, anxiety disorders, PTSD, and special interest in adult ADHD. He is committed to providing person-centred, holistic care to individuals, to bolster their strengths and achieve their goals.",
        shortBio: "Ricky completed his medical training in 2013, with a focus on adult mental health, and experience in a range of subspecialties."
    },     
    {
        id: "Aviva",
        title: "Consultant Psychiatrist",
        name: "Dr Aviva Capelluto",
        credentials: "MBBCH(Wits), FCPSYCH(SA), FRANZCP",
        state: "VIC",
        imageFile: imgAviva,
        bio: "Aviva has 15 years of experience as a consultant psychiatrist and has worked in various settings, including leading large multidisciplinary teams in two public adult acute psychiatry inpatient units, providing consultation liaison services within a private ICU setting, and managing her own outpatient private practice. Aviva has managed various psychiatric conditions in children, adolescents, and adults for many years, and has gained understanding of how these conditions manifest across the lifespan. In private practice she became passionate about identifying and managing symptoms of ADHD and ASD (autism spectrum disorder) in children and adults. Aviva has special interests in ADHD and ASD, as well as mood, anxiety, and psychotic disorders, as well as the pharmacotherapeutic and non-pharmacological management of these conditions and their psychiatric and medical co-morbidities. In addition, she is passionate about women’s mental health, as well as supporting clients with mental health conditions within the LGBTQI+ community. Aviva strives to provide holistic and high-quality care, education and information, as well as nuanced and holistic recommendations for treatment.",
        shortBio: "Aviva has over 15 years of experience in various settings, including public and private acute psychiatry inpatient units. She has a special interest in ADHD, ASD, mood, anxiety and psychotic disorders, and has a holistic approach to care."
    },       
    {
        id: "Kylie",
        title: "Consultant Psychologist",
        name: "Kylie Agnew",
        credentials: "BPsych(Hons), BSc",
        state: "SA",
        imageFile: imgKylie,
        bio: "Kylie is an AHPRA registered (and NDIS accredited) psychologist and APS member. She has experience providing evidence-based treatments for depression, anxiety, personality disorders, stress, grief, and interpersonal difficulties across a wide range of clients. Her therapeutic style is warm and practical, and her clients feel understood, accepted and equipped with psychological tools to use every day. She draws from treatment modalities including Cognitive Behaviour Therapy, Brief Solution Focussed Therapy, Dialectical Behavioural Therapy and Motivational Interviewing.",
        shortBio: "Kylie is an AHPRA registered psychologist with experience in providing evidence-based treatments for depression, anxiety, personality disorders, stress, grief and interpersonal difficulties."
    },
    {
        id: "Christine",
        title: "Consultant Mental Health Nurse",
        name: "Christine Latham",
        credentials: "RN, MHN",
        state: "NSW",
        imageFile: imgChristine,
        bio: "Christine is an AHPRA Registered Nurse. She has substantial experience within acute/rehabilitation mental health settings, across both public and private hospitals. Christine provides therapeutic support, assessment and education to her clients with much empathy and kindness - working with a special interest and knowledge in the areas of Depression, Anxiety, PTSD, Bipolar, Schizophrenia, Adult ADHD, Personality and mood disorders.",
        shortBio: "Christine is an AHPRA Registered Nurse with experience in acute/rehabilitation mental health settings, and a special interest in Depression, Anxiety, PTSD, Bipolar, Schizophrenia, Adult ADHD, Personality and mood disorders."
    },
    {
        id: "Roxanne",
        title: "Consultant Mental Health Nurse",
        name: "Roxanne Bryson",
        credentials: "RN, MHN",
        state: "NSW",
        imageFile: imgRoxi,
        bio: "Roxanne is a trained mental health nurse with 14 years experience. She has extensive experience working alongside clients with a broad range of mental health diagnosis in both inpatient and community mental health care settings. Her practice is trauma informed and client centred. She has a special interest in adult ADHD. Roxanne is passionate about her role in supporting clients through their recovery journey.",
        shortBio: "Roxanne is a trained mental health nurse with 14 years experience, with a special interest in adult ADHD."
    },
    {
        id: "Taren",
        title: "Consultant Mental Health Nurse",
        name: "Taren Kowalski",
        credentials: "MMHN",
        state: "VIC",
        imageFile: imgTaren,
        bio: "Taren has over 12 years of mental health experience in both public and private health services across New Zealand and Australia. With a Masters in Mental Health Nursing, and accredited with the ACMHN, she is passionate in providing consistent quality care to individuals to help better understand their condition. Taren tailors care with a holistic focus and has a special interest in ADHD, mood disorders and acute mental health conditions.",
        shortBio: "Taren has over 12 years of mental health experience, with a focus on ADHD, mood disorders and acute mental health conditions."
    }
];; 

export const admins = [
    {
        id: "Moira",
        title: "Operations Co-ordinator",
        name: "Moira F",
        credentials: "Medical and Legal Secretarial Diploma",
        state: "SA",
        imageFile: imgFemale,
        bio: "Moira..."
    },    
    {
        id: "Fiona",
        title: "Practice Manager",
        name: "Fiona D",
        credentials: "Medical and Legal Secretarial Diploma",
        state: "SA",
        imageFile: imgFemale,
        bio: "Fiona has worked in an administrative capacity within health and education; supporting psychologists, medical specialists and other health and teaching professionals."
    },
    {
        id: "Zeljka",
        title: "Senior Administrator",
        name: "Zeljka D",
        credentials: "Medical and Legal Secretarial Diploma",
        state: "SA",
        imageFile: imgFemale,
        bio: "Zeljka brings a wealth of experience to Akkadian Health having worked in both General Practice and Specialist medical clinics in South Australia for over a decade."
    },
    {
        id: "Olivia",
        title: "Senior Administrator",
        name: "Olivia Z",
        credentials: "Medical and Legal Secretarial Diploma",
        state: "SA",
        imageFile: imgFemale,
        bio: "Olivia is a senior administrator with over 15 years of experience. She has worked in both General Practice and Specialist medical clinics and has a special interest in mental health."
    },
    {
        id: "Ally",
        title: "Senior Administrator",
        name: "Ally J",
        credentials: "Medical and Legal Secretarial Diploma",
        state: "SA",
        imageFile: imgFemale,
        bio: "Ally..."
    },   
    {
        id: "Gonzalo",
        title: "Senior Software Manager",
        name: "Gonzalo C",
        credentials: "BEng. (Hons.) Computer Science",
        state: "SA",
        imageFile: imgMale,
        bio: "Gonzalo"
    },
    {
        id: "Mehera",
        title: "Administrator",
        name: "Mehera F",
        credentials: "Medical and Legal Secretarial Diploma",
        state: "SA",
        imageFile: imgFemale,
        bio: "Mehera..."
    },
    {
        id: "Lucy",
        title: "Administrator",
        name: "Lucy D",
        credentials: "Medical and Legal Secretarial Diploma",
        state: "SA",
        imageFile: imgFemale,
        bio: "Lucy..."
    },    
]

export const states = [
    { name: 'NSW', color: "skyblue", textColor: "navy", filename: false }, // sky blue (navy)
    { name: 'NT', color: "#C25E03", textColor: "black", filename: "nt" }, // red ochre (black)
    { name: 'ACT', color: "blue", textColor: "gold", filename: "act" }, // blue (gold)
    { name: 'VIC', color: "navy", textColor: "silver", filename: "victoria" }, // navy blue (silver)
    { name: 'QLD', color: "maroon", textColor: "white", filename: "queensland" }, // maroon (light/white)
    { name: 'SA', color: "red", textColor: "gold", filename: "south-australia" }, // red (gold)
    { name: 'WA', color: "gold", textColor: "black", filename: false }, // gold (black)
    { name: 'TAS', color: "#006747", textColor: "yellow", filename: "tasmania" }, // bottle green (yellow)
];
